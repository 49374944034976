import * as React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "/src/components/layouts/default"
import Seo from "/src/components/helpers/seo"

const IndexPage = ({ data }) => {
  const articles = data.allNodeArticle.nodes;
  const pages = data.allNodePage.nodes;
  const details = data.allNodeDetailPage.nodes;
  console.log(articles);
  console.log(pages);
  return (
    <Layout currentLang="fr">
     <Seo title="Index FR" />
      <div className="twoColumns">
      <div className="articlesList">
      <h2>Here is some test articles:</h2>
      {articles.map(article => (
        <div key={article.path.alias} className="article">
        <Link to={"/fr" + article.path.alias}>{article.title}</Link>
        </div>
      ))}
      </div>
      <div className="articlesList">
      <h2>Here is some test pages:</h2>
      {pages.map(page => (
        <div key={page.path.alias} className="article">
        <Link to={"/fr" + page.path.alias}>{page.title}</Link>
        </div>
      ))}
      </div>
      <div className="articlesList">
      <h2>Here is some test details:</h2>
      {details.map(page => (
        <div key={page.path.alias} className="article">
        <Link to={"/fr" + page.path.alias}>{page.title}</Link>
        </div>
      ))}
      </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ContentListsFR {
  allNodeArticle(filter: {langcode: {eq: "fr"}}) {
    nodes {
      id
      body {
        processed
      }
      path {
        alias
      }
      title
    }
  }
  allNodePage(filter: {langcode: {eq: "fr"}}) {
    nodes {
      body {
        processed
      }
      id
      path {
        alias
      }
      title
    }
  }
  allNodeDetailPage(filter: {langcode: {eq: "fr"}}) {
    nodes {
      id
      path {
        alias
      }
      title
    }
  }
}
`;